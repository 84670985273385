@import '../theme-bootstrap';

// Skeleton Product Full.
.sd-full-skeleton {
  .skeleton-full {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-width: $max-width-cr21;
    margin: 0 auto;
  }
  .elc-product-full {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-width: $max-width-cr21;
    margin: 0 auto;
  }
  // Product Full.
  .elc-skeleton-region {
    // SPP Layout Sections.
    &--product-detail,
    &--product-visual {
      position: relative;
      width: 100%;
    }
    &--product-detail {
      order: 2;
      padding: 0 20px;
      margin-top: 28px;
      @include breakpoint($landscape-up) {
        padding-inline-start: 25px;
        width: 47%;
      }
    }
    &--product-visual {
      order: 1;
      @include breakpoint($landscape-up) {
        margin: 22px 0 0;
        padding-inline-end: 0;
        width: 53%;
      }
    }
    &--product-info {
      order: 3;
      margin: 20px 0;
      padding: 0 20px;
      @include breakpoint($landscape-up) {
        width: 47.1%;
        min-width: 610px;
      }
      @include breakpoint($xlarge-up) {
        width: 42.1%;
      }
    }
    .elc-skeleton {
      &-feature {
        &--displayName {
          margin: 0;
          h1 {
            @include spp-headings;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            display: flex;
            flex-direction: column-reverse;
            @include breakpoint($portrait-up) {
              margin: 8px 0 0;
            }
            @include breakpoint($landscape-up) {
              margin: 4px 0 0;
              font-size: 20px;
            }
          }
        }
        &--ratingAndReviews {
          color: $color-black;
          font-family: $font--helvetica-neue-regular;
          letter-spacing: $base-letter-spacing;
          font-size: get-rem(14px);
          line-height: get-line-height(12px, 12px);
          padding: 0;
          border: none;
          text-decoration: none;
          text-transform: uppercase;
          margin: 12px 0;
          @include breakpoint($landscape-up) {
            margin: 24px 0;
          }
        }
        &--shortDescription {
          font-family: $font--helvetica-neue-regular;
          letter-spacing: $base-letter-spacing;
          margin: 0 0 24px;
          font-size: get-rem(14px);
          line-height: get-line-height(14px, 16px);
          @include breakpoint($portrait-up) {
            font-size: get-rem(18px);
            line-height: get-line-height(18px, 18px);
          }
          @include breakpoint($landscape-up) {
            font-size: get-rem(13px);
            line-height: get-line-height(13px, 16px);
          }
        }
        &--autoReplenish {
          @include shimmer(1.5s, ease-in-out);
          height: 24px;
          margin-bottom: 18px;
          width: 180px;
        }
        &--price {
          @include shimmer(1.5s, ease-in-out);
          height: 28px;
          margin-bottom: 10px;
          width: 100px;
        }
        &--installmentsPrice {
          @include shimmer(1.5s, ease-in-out);
          height: 20px;
          width: 250px;
        }
        &--addToBag {
          @include shimmer(1.5s, ease-in-out);
          height: 53px;
          width: 90%;
          margin: 22px 0;
          background: $color-black;
          @include breakpoint($landscape-up) {
            width: 90%;
          }
        }
        &--sizePicker {
          @include shimmer(1.5s, ease-in-out);
          height: 42px;
          margin-bottom: 20px;
          width: 225px;
        }
        &--carousel {
          @include breakpoint($landscape-up) {
            position: relative;
            margin-#{$ldirection}: 17%;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        &--productInfoTabs {
          font-family: $font--helvetica-neue-regular;
          letter-spacing: $base-letter-spacing;
          font-size: get-rem(14px);
          line-height: get-line-height(14px, 16px);
          @include breakpoint($landscape-up) {
            font-family: $font--helvetica-neue-light;
            font-size: get-rem(24px);
            line-height: get-line-height(24px, 24px);
          }
          h2 {
            color: $color-black;
            font-family: $font--helvetica-neue-bold;
            letter-spacing: $base-letter-spacing;
            font-size: get-rem(16px);
            font-weight: bold;
            line-height: get-line-height(16px, 16px);
            text-transform: uppercase;
            @include breakpoint($landscape-up) {
              font-size: get-rem(22px);
              line-height: get-line-height(22px, 22px);
            }
            @include breakpoint($xlarge-up) {
              font-size: get-rem(24px);
              line-height: get-line-height(24px, 24px);
            }
          }
        }
      }
    }
  }
}
